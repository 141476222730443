import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import { getFirestore, collection, query, getDocs } from "firebase/firestore";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Format numbers as whole numbers
const formatNumber = (num) => {
  return num.toLocaleString("en-US", { maximumFractionDigits: 0 });
};

const Dashboard = () => {
  const [monthlyData, setMonthlyData] = useState([]);
  const [dailyData, setDailyData] = useState([]);
  const [graphData, setGraphData] = useState({
    net: [],
    gross: [],
    totalPayments: { new: [], recurring: [] },
  });
  const [dailyGraphData, setDailyGraphData] = useState({
    totalPayments: { new: [], recurring: [] },
  });
  const [tableData, setTableData] = useState([]);
  const [yearlyTotals, setYearlyTotals] = useState({});
  const [currentMonthData, setCurrentMonthData] = useState({ net: 0, projected: 0 });

  // Fetch Firestore data and aggregate by month and day
  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const dailyCollection = collection(
        db,
        "analytics",
        "premiumSubscribers",
        "daily"
      );
      const snapshot = await getDocs(query(dailyCollection));

      const monthlyAggregate = {};
      const dailyAggregate = [];
      const yearlyData = {};
      const currentMonthKey = `${new Date().getFullYear()}-${String(
        new Date().getMonth() + 1
      ).padStart(2, "0")}`;

      snapshot.forEach((doc) => {
        const date = new Date(doc.id); // Parse the Firestore doc ID as a date
        const monthKey = `${date.getFullYear()}-${String(
          date.getMonth() + 1
        ).padStart(2, "0")}`; // Format YYYY-MM
        const yearKey = `${date.getFullYear()}`;

        if (!monthlyAggregate[monthKey]) {
          monthlyAggregate[monthKey] = {
            net: 0,
            gross: 0,
            totalPayments: 0,
            newPayments: 0,
            recurringPayments: 0,
          };
        }

        if (!yearlyData[yearKey]) {
          yearlyData[yearKey] = { net: 0, gross: 0 };
        }

        const dailyData = doc.data();
        monthlyAggregate[monthKey].net += dailyData.net || 0;
        monthlyAggregate[monthKey].gross += dailyData.gross || 0;
        monthlyAggregate[monthKey].totalPayments += dailyData.totalPayments || 0;
        monthlyAggregate[monthKey].newPayments += dailyData.totalNew || 0;
        monthlyAggregate[monthKey].recurringPayments += dailyData.totalRecurring || 0;

        yearlyData[yearKey].net += dailyData.net || 0;
        yearlyData[yearKey].gross += dailyData.gross || 0;

        // Aggregate daily data for the last 30 days
        const today = new Date();
        const diffTime = Math.abs(today - date);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays <= 30) {
          dailyAggregate.push({
            date: doc.id,
            newPayments: dailyData.totalNew || 0,
            recurringPayments: dailyData.totalRecurring || 0,
          });
        }
      });

      // Set current month data
      const currentMonthNet = monthlyAggregate[currentMonthKey]?.net || 0;
      const currentMonthProjected = (currentMonthNet / new Date().getDate()) * 30;

      setCurrentMonthData({
        net: formatNumber(currentMonthNet),
        projected: formatNumber(currentMonthProjected),
      });

      // Convert aggregated data to arrays for graphs and tables
      const months = Object.keys(monthlyAggregate).sort(); // Ensure chronological order
      const graphNet = months.map((month) => monthlyAggregate[month].net);
      const graphGross = months.map((month) => monthlyAggregate[month].gross);
      const graphTotalPaymentsNew = months.map(
        (month) => monthlyAggregate[month].newPayments
      );
      const graphTotalPaymentsRecurring = months.map(
        (month) => monthlyAggregate[month].recurringPayments
      );

      setMonthlyData(months);
      setGraphData({
        net: graphNet,
        gross: graphGross,
        totalPayments: {
          new: graphTotalPaymentsNew,
          recurring: graphTotalPaymentsRecurring,
        },
      });

      // Prepare daily graph data
      const dailyLabels = dailyAggregate.map((entry) => entry.date);
      const dailyNewPayments = dailyAggregate.map((entry) => entry.newPayments);
      const dailyRecurringPayments = dailyAggregate.map(
        (entry) => entry.recurringPayments
      );

      setDailyData(dailyLabels);
      setDailyGraphData({
        totalPayments: {
          new: dailyNewPayments,
          recurring: dailyRecurringPayments,
        },
      });

      // Prepare table data
      setTableData(
        months.map((month) => ({
          month,
          profit: formatNumber(monthlyAggregate[month].net),
          gross: formatNumber(monthlyAggregate[month].gross),
          totalPayments: formatNumber(monthlyAggregate[month].totalPayments),
          newPayments: formatNumber(monthlyAggregate[month].newPayments),
          recurringPayments: formatNumber(monthlyAggregate[month].recurringPayments),
        }))
      );

      setYearlyTotals(yearlyData);
    };

    fetchData();
  }, []);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Analytics Dashboard</h1>

      {/* Current Month Data */}
      <div className="mb-8">
        <h2 className="text-xl font-bold">
          Current Month: Net - {currentMonthData.net}, Projected -{" "}
          {currentMonthData.projected}
        </h2>
      </div>

      {/* Graphs */}
      <div className="grid grid-cols-2 gap-6 mb-8">
        <div className="col-span-1">
          <h2 className="text-xl font-semibold mb-2">Net Over 12 Months</h2>
          <div className="h-96">
            <Line
              data={{
                labels: monthlyData,
                datasets: [
                  {
                    label: "Net",
                    data: graphData.net,
                    borderColor: "rgba(75,192,192,1)",
                    borderWidth: 2,
                    fill: false,
                  },
                ],
              }}
              options={{ responsive: true, plugins: { legend: { position: "top" } } }}
            />
          </div>
        </div>

        <div className="col-span-1">
          <h2 className="text-xl font-semibold mb-2">Gross Over 12 Months</h2>
          <div className="h-96">
            <Line
              data={{
                labels: monthlyData,
                datasets: [
                  {
                    label: "Gross",
                    data: graphData.gross,
                    borderColor: "rgba(255,99,132,1)",
                    borderWidth: 2,
                    fill: false,
                  },
                ],
              }}
              options={{ responsive: true, plugins: { legend: { position: "top" } } }}
            />
          </div>
        </div>

        <div className="col-span-2">
          <h2 className="text-xl font-semibold mb-2">Total Payments Over 12 Months</h2>
          <div className="h-96">
            <Bar
              data={{
                labels: monthlyData,
                datasets: [
                  {
                    label: "New Payments",
                    data: graphData.totalPayments.new,
                    backgroundColor: "rgba(54, 162, 235, 0.6)",
                  },
                  {
                    label: "Recurring Payments",
                    data: graphData.totalPayments.recurring,
                    backgroundColor: "rgba(153, 102, 255, 0.6)",
                  },
                ],
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: { position: "top" },
                  tooltip: {
                    callbacks: {
                      label: (context) =>
                        `${context.dataset.label}: ${formatNumber(context.raw)}`,
                    },
                  },
                },
                scales: {
                  x: { stacked: true },
                  y: { stacked: true },
                },
              }}
            />
          </div>
        </div>

        <div className="col-span-2">
          <h2 className="text-xl font-semibold mb-2">Total Payments (Last 30 Days)</h2>
          <div className="h-96">
            <Bar
              data={{
                labels: dailyData,
                datasets: [
                  {
                    label: "New Payments",
                    data: dailyGraphData.totalPayments.new,
                    backgroundColor: "rgba(54, 162, 235, 0.6)",
                  },
                  {
                    label: "Recurring Payments",
                    data: dailyGraphData.totalPayments.recurring,
                    backgroundColor: "rgba(153, 102, 255, 0.6)",
                  },
                ],
              }}
              options={{
                responsive: true,
                plugins: {
                  legend: { position: "top" },
                  tooltip: {
                    callbacks: {
                      label: (context) =>
                        `${context.dataset.label}: ${formatNumber(context.raw)}`,
                    },
                  },
                },
                scales: {
                  x: { stacked: true },
                  y: { stacked: true },
                },
              }}
            />
          </div>
        </div>
      </div>

      {/* Yearly Totals Table */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Yearly Totals</h2>
        <table className="w-full table-auto border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border border-gray-300 px-2 py-1">Year</th>
              <th className="border border-gray-300 px-2 py-1">Net</th>
              <th className="border border-gray-300 px-2 py-1">Gross</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(yearlyTotals).map(([year, totals]) => (
              <tr key={year}>
                <td className="border border-gray-300 px-2 py-1 text-center">{year}</td>
                <td className="border border-gray-300 px-2 py-1 text-center">
                  {formatNumber(totals.net)}
                </td>
                <td className="border border-gray-300 px-2 py-1 text-center">
                  {formatNumber(totals.gross)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Monthly Summary Table */}
      <div>
        <h2 className="text-xl font-semibold mb-2">Monthly Summary</h2>
        <table className="w-full table-auto border-collapse border border-gray-300 text-sm">
          <thead>
            <tr>
              <th className="border border-gray-300 px-2 py-1">Month</th>
              <th className="border border-gray-300 px-2 py-1">Profit</th>
              <th className="border border-gray-300 px-2 py-1">Gross</th>
              <th className="border border-gray-300 px-2 py-1">Total Payments</th>
              <th className="border border-gray-300 px-2 py-1">New Payments</th>
              <th className="border border-gray-300 px-2 py-1">Recurring Payments</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td className="border border-gray-300 px-2 py-1 text-center">{row.month}</td>
                <td className="border border-gray-300 px-2 py-1 text-center">{row.profit}</td>
                <td className="border border-gray-300 px-2 py-1 text-center">{row.gross}</td>
                <td className="border border-gray-300 px-2 py-1 text-center">{row.totalPayments}</td>
                <td className="border border-gray-300 px-2 py-1 text-center">{row.newPayments}</td>
                <td className="border border-gray-300 px-2 py-1 text-center">{row.recurringPayments}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Dashboard;

// 1. Can you make the table at the bottom smaller, doesn't have to take up the whole width of the screen. 
// 2. And, can you make most recent months at the top?
// 3. Can you show refunds too? 
// 4. Is all the net and gross taking into account refunds?